import React from "react"

import styles from "./heroText.module.scss"

const HeroText = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.titleWrapper}>
        Creating <span> beautiful </span>eLearning has never been so easy
        <span className={styles.dot} />
      </h1>
    </div>
  )
}

export default HeroText
