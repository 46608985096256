import React from "react"
import cx from "classnames"
import Reveal from "react-reveal/Reveal"
import Img from "gatsby-image"

import HeroText from "./heroText/heroText"
import VideoInline from "../../videoInline/videoInline"
import InlineImage from "../../blockContent/inlineImage/inlineImage"

import styles from "./heroHomepage.module.scss"

// todo fluid images?

const HeroHomepage = ({ data }) => {
  const { videoFile, fallbackImage, fallbackMobileImage } = data.sanityHomepage.tabs.content

  return (
    <Reveal effect="fadeInDown">
      <div className={cx("", styles.fullWidth)}>
        {/* LEFT */}
        <div className={cx(styles.item, styles.left)}>
          <div className={styles.top}>
            <div>
              <Img
                fixed={data.leftTablet.childImageSharp.fixed}
                className={styles.tablet}
                alt=""
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <div>
              <Img
                fixed={data.leftPhone.childImageSharp.fixed}
                className={styles.phone}
                alt=""
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className={styles.secondRow}>
            <Img
              fixed={data.leftBrowser.childImageSharp.fixed}
              className={styles.browser}
              alt=""
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
        </div>

        {/* CENTER */}
        <Reveal effect="fadeInDown">
          <div className={styles.central}>
            <HeroText />
            <div className={cx(styles.centralImg, styles.desktop)}>
              <VideoInline url={videoFile?.asset?.url} poster={fallbackImage?._rawAsset} visible={true} loop={true} />
            </div>
            <div className={cx(styles.centralImg, styles.mobile)}>
              <InlineImage image={fallbackMobileImage?._rawAsset} alt="" />
            </div>
          </div>
        </Reveal>

        {/* RIGHT */}
        <div className={cx(styles.item, styles.right)}>
          <div className={styles.top}>
            <div>
              <Img
                fixed={data.rightPhone.childImageSharp.fixed}
                className={styles.phone}
                alt=""
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <div>
              <Img
                fixed={data.rightTablet.childImageSharp.fixed}
                className={styles.tablet}
                alt=""
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className={styles.secondRow}>
            <Img
              fixed={data.rightBrowser.childImageSharp.fixed}
              className={styles.browser}
              alt=""
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
    </Reveal>
  )
}

export default HeroHomepage
