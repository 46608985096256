import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import HeroHomepage from "../components/hero/heroHomepage/heroHomepage"
import PageBuilder from "../components/pageBuilder/pageBuilder"
import Seo from "../components/seo/seo"

const IndexPage = ({ data }) => {
  const { content, seo } = data.sanityHomepage.tabs

  return (
    <Layout>
      <Seo {...seo} />
      <HeroHomepage data={data} />
      <PageBuilder pageBuilder={content.pageBuilder} />
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  query {
    sanityHomepage {
      tabs {
        content {
          pageBuilder {
            ...PageBuilder
          }
          videoFile {
            asset {
              url
            }
          }
          fallbackMobileImage {
            _rawAsset(resolveReferences: { maxDepth: 10 })
          }
          fallbackImage {
            _rawAsset(resolveReferences: { maxDepth: 10 })
          }
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    central: file(relativePath: { eq: "homepage/central_browser_screen.jpg" }) {
      childImageSharp {
        fixed(width: 576) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rightBrowser: file(relativePath: { eq: "homepage/right_browser_screen.png" }) {
      childImageSharp {
        fixed(width: 508) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rightPhone: file(relativePath: { eq: "homepage/right_phone_screen.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rightTablet: file(relativePath: { eq: "homepage/right_tablet_screen.png" }) {
      childImageSharp {
        fixed(width: 430) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    leftBrowser: file(relativePath: { eq: "homepage/left_browser_screen.png" }) {
      childImageSharp {
        fixed(width: 508) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    leftPhone: file(relativePath: { eq: "homepage/left_phone_screen.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    leftTablet: file(relativePath: { eq: "homepage/left_tablet_screen.png" }) {
      childImageSharp {
        fixed(width: 430) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
